$(function () {
  window.Geenie.Csrf = {
    /**
     * @param {{
     * onSuccess?: (data: {token: string}) => void;
     * onError?: () => void;
     * }} [options]
     */
    fetch_token: function ({ onSuccess, onError } = {}) {
      // APIへアクセスして、CSRFトークンをCOOKIEに保存する
      $.ajax({
        type: 'POST',
        url: '/api/csrf/fetch_token',
        cache: false,
        dataType: 'json',
      })
        .done(function (data) {
          onSuccess?.(data);
        })
        .fail(function () {
          onError?.();
        });
    },
  };
});
